import reactjs from "../logos/reactjs.svg"
import js from "../logos/js.svg";
import cpp from "../logos/cpp.svg";
import csharp from "../logos/csharp.svg";
import firebase from "../logos/firebase.svg";
import java from "../logos/java.svg";
import mongodb from "../logos/mongodb.svg";
import mysql from "../logos/mysql.svg";
import nodejs from "../logos/nodejs.svg";
import tailwind from "../logos/tailwind.svg";
import sqlserver from "../logos/sqlserver.svg";
import mongoose from "../logos/mongoose.svg";

export default function Skills() {
    return <section id="skills" className="max-w-[90%] lg:max-w-[60%] my-24 mx-auto">
        <h2 className="text-center tracking-widest text-3xl uppercase">skills</h2>
        <div className="flex gap-4 flex-wrap text-center justify-center my-8">
            <img src={js} alt="JS logo" className="h-[100px] w-[100px]"/>
            <img src={nodejs} alt="NodeJS logo" className="h-[100px] w-[100px]" />
            <img src={tailwind} alt="Tailwind logo" className="h-[100px] w-[100px]" />
            <img src={reactjs} alt="ReactJS logo" className="h-[100px] w-[100px]" />
            <img src={mysql} alt="mysql logo" className="h-[100px] w-[100px]" />
            <img src={sqlserver} alt="SQL Server logo" className="h-[100px] w-[100px]" />
            <img src={mongodb} alt="mongodb logo" className="h-[100px] w-[100px]" />
            <img src={mongoose} alt="mongoose logo" className="h-[100px] w-[100px]" />
            <img src={firebase} alt="firebase logo" className="h-[100px] w-[100px]" />
            <img src={java} alt="java logo" className="h-[100px] w-[100px]" />
            <img src={cpp} alt="cpp logo" className="h-[100px] w-[100px]" />
            <img src={csharp} alt="csharp logo" className="h-[100px] w-[100px]" />
        </div>

    </section>
}
import logo from './logo.svg';
import NavBar from './Components/NavBar';
import Header from './Components/Header';
import Skills from './Components/Skills';
import Experience from './Components/Experience';
import Projects from './Components/Projects';
import Footer from './Components/Footer';
import './App.css';

function App() {
  return (
    <>
    <NavBar />
    <Header />
    <Skills />
    <Experience />
    <Projects />
    <Footer />
    </>
  );
}

export default App;

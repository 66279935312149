import heroImage from "../ishjot-singh.jpeg";

import { useState, useEffect } from "react";

export default function Header() {
    const [index, setIndex] = useState(0);
    const [phrases, setPhrases] = useState(["A Software Developer", "Ishjot Singh Bhatia"]);
    const [currentText, setCurrentText] = useState("Ishjot Singh Bhatia");
    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
        const typingEffect = setInterval(() => {
            const fullText = phrases[index % phrases.length];
            setCurrentText(fullText.substring(0, currentText.length + 2));

            if (currentText === fullText) {
                clearInterval(typingEffect);
                setTimeout(() => {
                    const nextPhrase = phrases[(index + 1) % phrases.length];
                    setCurrentText(nextPhrase.charAt(0));
                    setIndex(prevIndex => prevIndex + 1);
                }, 2000); // Wait 2 seconds before moving to the next phrase
            }
        }, 100); // Typing speed (100 milliseconds per character)

        // Calculate container height based on largest phrase
        const maxPhraseLength = Math.max(...phrases.map(phrase => phrase.length));
        const lineHeight = parseInt(window.getComputedStyle(document.body).fontSize);
        const maxContainerHeight = maxPhraseLength * lineHeight;
        setContainerHeight(maxContainerHeight);

        return () => clearInterval(typingEffect);
    }, [index, currentText, phrases]);

    return (
        <section className="flex items-center gap-8 flex-col max-w-[90%] lg:max-w-[60%] my-8 mx-auto">
            <figure>
                <img src={heroImage} alt="Ishjot Singh" className="rounded-[50%] w-[250px] h-auto" />
            </figure>
            <div className="h-[{containerHeight}px]">
                <h2 className="text-4xl font-extrabold text-center">
                Hey, I am
                    <span className="bg-gradient-to-r from-violet-500 to-fuchsia-500 bg-clip-text text-transparent block py-2 text-5xl lg:text-7xl">
                        {currentText}
                    </span>
                </h2>
            </div>
            <p className="text-center text-xl">
                I'm a full-stack developer fueled by curiosity and a drive to constantly learn and improve.
                Committed to staying updated with the latest technologies, I strive to continuously upskill myself.
                Let's innovate and grow together!
            </p>
            <div className="flex flex-col lg:flex-row gap-6">
                <a href="mailto:ishjotsb26@gmail.com" className="py-4 px-8 bg-white text-black rounded-[12px] font-bold text-lg">Get In Touch</a>
                <a href="#" className="py-4 px-8 bg-black text-white border-2 rounded-[12px] font-bold text-lg">Download CV</a>
            </div>
        </section>
    );
}

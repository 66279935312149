import { useState } from "react";
import sportify from "../sportify.png";
import jamn from "../Welcome.jpg";

export default function Projects() {
    const [isHovered, setIsHovered] = useState({first:false, second:false});

    return (
        <section className="max-w-[90%] lg:max-w-[60%] my-8 mx-auto text-lg" id="projects">
            <h2 className="uppercase text-4xl font-black bg-gradient-to-b from-[#FF8660] to-[#D5491D] bg-clip-text text-transparent text-center">Projects</h2>
            <div className="grid lg:grid-cols-2 gap-4 my-16">
                <div className="relative" onMouseEnter={() => setIsHovered({first:true, second:false})} onMouseLeave={() => setIsHovered({first:false, second:false})}>
                    <img 
                        src={jamn} 
                        alt="" 
                        className={`rounded-t-md aspect-video`}
                    />
                    <div className={`p-2 bg-[#000000c9] absolute bottom-0 z-10 w-full transition-all duration-1000 transform ${isHovered.first ? 'translate-y-[-55%]' : '-translate-y-full opacity-0'}`}>
                        <p className="text-white text-center py-1">Where melodies meet: A platform for musicians to connect, collaborate, and jam together, creating musical masterpieces and unforgettable experiences.</p>
                        <p className="text-white text-center"><span className="font-bold">Technologies Used</span>: NodeJS, ExpressJS, Firebase, TomTom Maps</p>
                    </div>
                    <div className="bg-[#111111] px-4 py-3 rounded-b-md">
                        <h3 className="text-lg font-extrabold">Jamn</h3>
                        <p>Check it out <a href="https://github.com/ishjtosb/Jamn" className="underline">here</a></p>
                    </div>
                </div>
                <div className="relative" onMouseEnter={() => setIsHovered({first:false, second:true})} onMouseLeave={() => setIsHovered({first:false, second:false})}>
                    <img 
                        src={sportify} 
                        alt="" 
                        className=" rounded-t-md aspect-video"
                    />
                    <div className={`p-2 bg-[#000000c9] absolute bottom-0 z-10 w-full transition-all duration-1000 transform ${isHovered.second ? 'translate-y-[-65%]' : '-translate-y-full opacity-0'}`}>
                        <p className="text-white text-center py-1">Explore sports fervor through expert analyses, captivating stories, and comprehensive coverage. Your ultimate destination for all things sports!</p>
                        <p className="text-white text-center"><span className="font-bold">Technologies Used</span>: WordPress</p>
                    </div>
                    <div className="bg-[#111111] px-4 py-3 rounded-b-md">
                        <h3 className="text-lg font-extrabold">Sportify</h3>
                        <p>Check it out <a href="https://ibhatia00.wmdd-wordpress.com/Ishjot-Bhatia-final-project/" className="underline">here</a></p>
                    </div>
                </div>
            </div>
        </section>
    );
}

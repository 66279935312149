export default function NavBar() {
    
    return <header className="bg-[#222222] flex items-center justify-between px-4 md:px-8 py-4">
        <h1 className="text-xl font-bold">Ishjot Singh</h1>
        <nav>
            <a href="mailto:ishjotsb26@gmail.com" className="z-10 md:p-4 bg-fuchsia-70 md:hidden text-right underline">Get in touch</a>
            <ul className="hidden md:flex md:gap-4">
                <li><a href="#skills">Skills</a></li>
                <li><a href="#experience">Experience</a></li>
                <li><a href="#projects">Projects</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
        </nav>
    </header>
} 